.GET {
    color: blue;
}

.POST {
    color: green;
}

.DELETE {
    color: red;
}